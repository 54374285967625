.prose {
  --tw-prose-bullets: theme(colors.gray.darker);
}

.prose :where(li):not(:where([class~='not-prose'] *)) {
  @apply my-3;
}

.prose :where(ul):not(:where([class~='not-prose'] *)) {
  @apply pl-4;
}

.streamblock-rich-text-large {
  p {
    @apply text-edt-lg text-blue;
  }

  a {
    @apply font-bold text-orange hover:underline;
  }
}

.streamblock-rich-text-standard {
  p {
    @apply text-edt-base text-blue lg:text-edt-lg;
  }
}
