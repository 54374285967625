@layer components {
  .hamburger {
    @apply relative inline-block h-7 w-7;
  }

  .hamburger-inner {
    &,
    &:before,
    &:after {
      @apply bg-blue;
      border-radius: 2px;
      height: 2px;
      position: absolute;
      transition-duration: 150ms;
      transition-property: transform;
      transition-timing-function: ease;
      width: 28px;
    }

    display: block;
    margin-top: -2px;
    top: 50%;
    transition-duration: 75ms;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &:before,
    &:after {
      content: '';
      display: block;
    }

    &:before {
      top: -8px;
      transition:
        top 75ms ease 120ms,
        opacity 75ms ease;
    }

    &:after {
      bottom: -8px;
      transition:
        bottom 75ms ease 120ms,
        transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  .hamburger.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 120ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .hamburger.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0;
    transition:
      top 75ms ease,
      opacity 75ms ease 120ms;
  }

  .hamburger.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition:
      bottom 75ms ease,
      transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 120ms;
  }
}
