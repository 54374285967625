@layer components {
  .btn {
    @apply inline-flex h-11 cursor-pointer items-center justify-center rounded-lg border px-4 align-bottom text-btn font-bold transition-colors focus:outline-black disabled:cursor-not-allowed disabled:opacity-50;
  }

  .btn-primary {
    @apply btn border-orange bg-orange text-white hover:bg-orange-dark;
  }

  .btn-primary-alt {
    @apply btn border-orange bg-white text-orange hover:bg-orange-dark hover:text-white;
  }

  .btn-secondary {
    @apply btn border-blue bg-blue text-white hover:bg-blue-dark;
  }

  .btn-secondary-alt {
    @apply btn border-blue bg-white text-blue hover:bg-blue-dark hover:text-white;
  }
}
