.c-loader {
  border-radius: 100%;
  height: 32px;
  margin: 0 auto;
  position: relative;
  width: 32px;
}

.c-loader:before,
.c-loader:after {
  border: 5px solid transparent;
  border-radius: 100%;
  border-top-color: theme('colors.blue.dark');
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-loader:before {
  animation: spin 1s infinite;
  z-index: 100;
}

.c-loader:after {
  border: 5px solid #ccc;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
