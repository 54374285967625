@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '.base.css';
@import 'components/.components.css';
@import 'utils/.utils.css';

@import 'air-datepicker/air-datepicker.css';
@import 'vendor-extensions/.vendor-extensions.css';
