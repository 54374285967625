.air-datepicker {
  font-family: 'PT Sans';
}

.air-datepicker-nav--title {
  @apply lowercase;
}

.air-datepicker-body--day-name {
  @apply lowercase;
}
