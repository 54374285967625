@layer components {
  .heading-1 {
    @apply text-x-large font-bold text-blue;
  }

  .heading-2 {
    @apply text-large font-bold text-blue;
  }

  .heading-3 {
    @apply text-base font-bold text-blue;
  }
}
