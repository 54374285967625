@layer components {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  .custom-file-input,
  textarea,
  select {
    @apply block w-full rounded-xl border border-current bg-transparent text-base leading-4 ring-blue focus:border-current focus:ring-1 focus:ring-current;

    & ~ label {
      @apply absolute left-3 top-0 z-0 -translate-y-1/2 px-1 text-small leading-4 opacity-0 duration-300;
    }

    & ~ span {
      @apply text-current;
    }

    &:focus-within ~ label,
    &:disabled ~ label,
    &:not(:placeholder-shown) ~ label,
    &.always_show_label ~ label {
      @apply opacity-100;
    }

    /* DISABLED STATE */

    &:disabled,
    &.is-disabled ~ label,
    &.is-disabled ~ span,
    &:disabled ~ label,
    &:disabled ~ span {
      @apply cursor-not-allowed border-gray-dark text-gray-dark;
    }
  }

  [type='checkbox'],
  [type='radio'],
  [type='file'] {
    &:disabled,
    &:disabled ~ label,
    &:disabled ~ span {
      @apply cursor-not-allowed border-gray-dark text-gray-dark;
    }
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  select {
    @apply h-9;
  }

  select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
    @apply cursor-pointer;
  }

  textarea {
    @apply resize-none;
  }
}
