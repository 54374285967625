@layer components {
  html.has-open-modal,
  html.has-open-modal body {
    @apply overflow-hidden lg:overflow-auto;

    @media (max-width: theme(screens.lg)) {
      height: calc(var(--window-inner-height) - 1px);
    }
  }
}
